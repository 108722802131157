import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "site-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header = _resolveComponent("page-header")
  const _component_room_details = _resolveComponent("room-details")
  const _component_alternative_rooms = _resolveComponent("alternative-rooms")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_page_header, {
      image: "ladder",
      title: _ctx.currentRoom.name
    }, null, 8, ["title"]),
    _createVNode("main", _hoisted_1, [
      (_ctx.contact)
        ? (_openBlock(), _createBlock(_component_room_details, {
            key: 0,
            room: _ctx.currentRoom,
            address: _ctx.contact.address,
            phone: _ctx.contact.phone,
            email: _ctx.contact.email
          }, null, 8, ["room", "address", "phone", "email"]))
        : _createCommentVNode("", true),
      (_ctx.alternativeRooms.length)
        ? (_openBlock(), _createBlock(_component_alternative_rooms, {
            key: 1,
            rooms: _ctx.alternativeRooms
          }, null, 8, ["rooms"]))
        : _createCommentVNode("", true)
    ])
  ]))
}