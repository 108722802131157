
import { defineComponent } from 'vue';

interface RoomWidgetMiniData {
  location: string;
}

export default defineComponent({
  name: 'RoomWidgetMini',
  props: ['room'],
  data(): RoomWidgetMiniData {
    return {
      location: window.location.pathname.split("/")[1]
    }
  }
});
