
import { defineComponent, nextTick } from 'vue';
import { getRooms, ParsedItem, getContact } from '../lib/Contentful'
import PageHeader from '../components/PageHeader.vue'
import RoomDetails from '../components/RoomDetails.vue'
import AlternativeRooms from '../components/AlternativeRooms.vue'

interface RoomData {
  rooms: ParsedItem[];
  contact: ParsedItem;
  location: string;
}

export default defineComponent({
  name: 'Rooms',
  data(): RoomData {
    return {
      rooms: [],
      contact: null,
      location: window.location.pathname.split("/")[1]
    }
  },
  components: {
    PageHeader,
    RoomDetails,
    AlternativeRooms
  },
  async mounted() {
    await this.getRooms(this.location);
    await this.getContact(this.location)
    const w = window as any;
    w.fbq('track', 'ViewContent');
  },
  methods: {
    async getRooms(location: string) {
      this.rooms = await getRooms(location)
    },
    async getContact(location: string) {
      this.contact = await getContact(location)
    }
  },
  computed: {
    alternativeRooms(): ParsedItem[] {
      return this.rooms.filter(room => room.url !== this.$route.params.room) || []
    },
    currentRoom(): ParsedItem {
      return this.rooms.find(room => room.url === this.$route.params.room) || {}
    }
  }
});
