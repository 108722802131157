
import { defineComponent } from 'vue';
import RoomWidgetMini from '../components/RoomWidgetMini.vue'

export default defineComponent({
  name: 'AlternativeRooms',
  props: ['rooms'],
  components: {
    RoomWidgetMini
  }
});
