
import { defineComponent, nextTick } from 'vue';

interface RoomDetailsData {
  location: string;
}

export default defineComponent({
  name: 'RoomDetails',
  props: ['room', 'address', 'phone', 'email'],
  data(): RoomDetailsData {
    return {
      location: window.location.pathname.split("/")[1]
    }
  },
  mounted() {
    nextTick(() => {
      const resovaAccount = this.location === 'middlesbrough' ? 'redrobotsystems' : 'pehexham'
      setTimeout(function() {
        (function(d, s, id) {
        const fjs: any = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        const js: any = d.createElement(s); js.id = id;
        js.src = "https://" + resovaAccount + ".resova.co.uk/widget/item/" + document.getElementById('resova-wrapper').getAttribute('data-id');
        fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'resova-pi'));
      }, 1000);
    })
  }
});
